import UmnPress from "./UmnPress";
import GraduateCenter from "./GraduateCenter";
import GcInitiatives from "./GcInitiatives";
import GcScholarship from "./GcScholarship";

export default {
  UmnPress,
  GraduateCenter,
  GcInitiatives,
  GcScholarship
};
